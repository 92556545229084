/* secton_main */
.section_main {
  overflow-x: hidden;
  position:relative;
}

.section h2.tit{
  font-size: 32px;
  margin-bottom: 5%;
}

.section .tit_sub {
  color: #a2a49e;
  font-size: 11px;
  padding-left:45px;
  position: relative;
  margin-bottom: 6%;
}

.section .tit_sub::before {
  content: "";
  width: 35px;
  height: 1px;
  background: #a2a49e;
  position: absolute;
  top: 6px;
  left: 0;
}

.section .btn_more,
.section .btn_more02 {
  margin-top: 5%;
}


/* section_mv
------------------------------------------------- */
.section_mv {
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 9;
}

.section_mv .concept_box {
  width: 100%;
  margin-top: -14%;
  padding: 0 5% 30%;
  position: relative;
  background: url(../img/top/img_concept.jpg) bottom center no-repeat;
  background-size: 100% auto;
}


.section_mv .concept_box .stit {
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 2px;
}

.section_mv .concept_box h2 {
  font-size: 63px;
  line-height: .85;
  position: relative;
  letter-spacing: 2px;
}

.section_mv .concept_box h2::after {
  content: "";
  position: absolute;
  top: -32px;
  left: 130px;
  width: 120px;
  height: 50px;
  background: url(../img/top/txt_concept.png) top center no-repeat;
  background-size: 100% auto;
  z-index: 99;
}

.section_mv .concept_box .txt_box {
  margin-top: 5%;
}

.section_mv .concept_box .txt_box .catch {
  font-size: 15px;
  font-weight: 700;
  line-height: 1.0;
  margin-bottom: 3%;
}

.section_mv .concept_box .txt_box .txt {
  font-size: 11px;
}


/* section_pickup
------------------------------------------------- */
.section_pickup {
  width: 100%;
  margin: -5% auto 0;
  position: relative;
  z-index: 99;
}

.section_pickup .list_box {
  margin: 0 5%;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -52px;
    left: -4%;
    width: 240px;
    height: 55px;
    background: url(../img/top/txt_pickup.png) top center no-repeat;
    background-size: 100% auto;
    z-index: -1;
  }
  .box {
    width: 100%;
    margin-bottom: 10%;
    .ph {
      margin-bottom: -36px;
      img {
        width: 100%;
      }
    }
    .num {
      display: inline-block;
      vertical-align: top;
      font-size: 80px;
      color: #dcd2a0;
      letter-spacing: -1px;
      margin: 0 2% 0 5%;
    }
    .tit_box {
      display: inline-block;
      vertical-align: top;
      padding-top: 42px;
      .tit {
        font-size: 26px;
      }
    }
  }
}


/* section_lineup
------------------------------------------------- */
.section_lineup {
  width: 100%;
  margin: 10% 0 0;
  position:relative;
  .inner {
    margin: 0;
    padding: 6% 0 15%;
    background: #f9f8ed;
  }
  .title_box {
    margin-bottom: -44px;
    padding-left: 5%;
    h2 {
      font-size: 50px;
      line-height: .8;
    }
    .stit {
      font-size: 13px;
      font-weight: 700;
    }
  }
  .list_box {
    margin-top: 8%;
    .box {
      width: 100%;
      text-align: center;
      a {
        display: block;
        padding: 0 3%;
      }
      .ph {
        img {
          width: 100%;
        }
      }
      .logo {
        margin:-46px auto 8px;
        position: relative;
        text-align: center;
        z-index: 9;
        img {
          height: 80px;
          width: auto;
          margin: 0 auto;
        }
      }
      .logo.type{
        margin-top: -40px;
        img {
          height:56px;
        }
      }
      .catch {
        font-size: 13px;
        font-weight: 700;
      }
    }
  }
}


/* section_works
------------------------------------------------- */

.section_works {
  width: 100%;
  position: relative;
  a {
    display: block;
    &:hover {
      opacity: 1;
    }
  }
}

.section_works .title_box {
  width: 215px;
  height: 185px;
  background: url(../img/top/bg_works.png) top center no-repeat;
  background-size: 100% auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: #FFF;
  text-align: center;
  padding: 54px 0 20px;
  z-index: 99;
  h2 {
    font-size: 34px;
    line-height: .8;
  }
  .stit {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 2%;
  }
  .txt {
    line-height: 1.4;
    font-size: 11px;
  }
  .more {
    font-size: 14px;
    margin-top: 3%;
  }
}

.section_works .loopslide {
  position: relative;
  z-index: -1;
}

/*  simply-scroll
---------------------------------------------------------- */

.simply-scroll-container {
  position: relative;
}

.simply-scroll-clip {
  overflow: hidden;
  position: relative;
}

.simply-scroll-list {
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.simply-scroll-list li {
  float: left;
  height: 118px;
  width: 170px;
  margin-right:0px;
  display: inline-block;
  img {
    width: 100%;
  }
}


/* section_event
------------------------------------------------- */

.section_event {
  width: 100%;
  overflow-x: hidden;
  padding: 18% 5% 15%;
  background: url(../img/cmn/bg.jpg) repeat;
  background-size: 600px auto;
  text-align: center;
  .title_box {
    margin-bottom: 5%;
    font-size: 13px;
    color: #FFF;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -20px;
      left: 50%;
      margin-left: -114px;
      width: 100px;
      height: 45px;
      background: url(../img/top/txt_event.png) top center no-repeat;
      background-size: 100% auto;
      z-index: 1;
    }
    h2 {
      font-size: 50px;
      line-height: .9;
    }
  }
  .list_box {
    margin-bottom: 12%;
    .box {
      background: #FFF;
      width: 100%;
      display: inline-block;
      vertical-align: top;
      text-align: left;
      a {
        padding: 7%;
        display: block;
      }
      .ph {
        width: 100%;
        height: 0;
        padding-bottom: 68%;
        overflow: hidden;
        margin-bottom: 3%;
        img {
          width: 100%;
        }
      }
      .tit {
        font-size: 14px;
        font-weight: 700;
        height: 50px;
        overflow: hidden;
        line-height: 1.6;
      }
      .date {
        color: #665b4f;
        font-size: 12px;
      }
    }
  }
  .btn_more {
    a {
      background: none;
      border :1px solid #FFF;
    }
  }
}


/* section_information
------------------------------------------------- */

.section_information {
  padding:15% 5%;
  text-align: center;
  .news_box {
    width: 100%;
    text-align: left;
    margin-bottom: 10%;
    .title_box {
      border-bottom: 1px solid #403223;
      padding-bottom: 6px;
      margin-bottom: 10px;
      h2 {
        display: inline-block;
        font-size: 38px;
        padding-right: 5px;
      }
      .stit {
        display: inline-block;
        font-size: 13px;
      }
      .btn_list {
        float: right;
        font-size: 15px;
        margin-top: 18px;
        span {
          font-size: 12px;
          padding-right: 8px;
        }
      }
    }
    .list_box {
      .box {
        a {
          padding: 8px 0;
          display: block;
        }
        .tit {
          font-size: 16px;
          font-weight: 700;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .date {
          font-size: 12px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
        }
        .icons {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
  .estate_box {
    position: relative;
    .title_box {
      font-size: 13px;
      text-align: right;
      margin-bottom: 4%;
      h2 {
        display: inline-block;
        font-size: 38px;
        padding-right: 5px;
      }
      .stit {
        display: inline-block;
        font-size: 13px;
      }
    }
    .ph_box {
      border:1px solid #c9c6c1;
      z-index: -1;
      padding: 8px;
      .ph {
        width: 100%;
        height: 0;
        padding-bottom: 68%;
        overflow: hidden;
        img {
          width: 100%;
          display: block;
        }
      }
    }
    .txt_box {
      background: #FFF;
      width: 84%;
      padding: 5%;
      margin: -10% auto 0;
      position: relative;
      text-align: center;
      z-index: 9;
      .tit {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.6;
      }
    }
  }
}


/* section_blog
------------------------------------------------- */

.section_blog {
  background: #fbfaf5;
  padding: 12% 5% 20%;
  text-align: center;
  .title_box {
    margin-bottom: 5%;
    text-align: left;
    h2 {
      display: inline-block;
      font-size: 38px;
      padding-right: 5px;
    }
    .stit {
      display: inline-block;
      font-size: 13px;
    }
    .btn_list {
      float: right;
      font-size: 15px;
      margin-top: 18px;
      span {
        font-size: 12px;
        padding-right: 8px;
      }
    }
  }
  .list_box {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    text-align: left;
    .box {
      width: 100%;
      &:last-child {
        margin-right: 0;
      }
      .ph {
        width: 100%;
        height:0;
        padding-bottom: 68%;
        overflow: hidden;
        margin-bottom: 4%;
        img {
          width: 100%;
        }
      }
      .date {
        font-size: 12px;
      }
      .icons {
        margin: 5px 0;
      }
      .tit {
        font-size: 15px;
        font-weight: 700;
        max-height: 75px;
        overflow: hidden;
      }
    }

  }
}


/*  section_voice
 *---------------------------------------------------------- */

.section_voice {
  margin: 15% auto 10%;
  .inner {
    margin: 0;
  }
  .ph_block {
    margin: -18% 5% 0;
    a {
      display: block;
    }
    .ph_box {
      border:1px solid #c9c6c1;
      z-index: -1;
      padding: 8px;
      .ph {
        width: 100%;
        height: 0;
        padding-bottom: 68%;
        overflow: hidden;
        img {
          width: 100%;
          display: block;
        }
      }
    }
    .txt_box {
      background: #FFF;
      position: relative;
      z-index: 99;
      width: 84%;
      padding: 5%;
      margin: -10% auto 0;
      text-align: center;
      .catch {
        font-size: 14px;
        margin-bottom: 2%;
        color: #84b8b5;
      }
      .tit {
        font-size: 14px;
        max-height: 50px;
        font-weight: 600;
      }
    }
  }
  .txt_block {
    width: 100%;
    text-align: center;
    padding-bottom: 30%;
    background: url(../img/top/img_concept.jpg) bottom center no-repeat;
    background-size: 100% auto;
    .title_box {
      margin-bottom: 5%;
      h2 {
        font-size: 50px;
      }
      .stit {
        font-size: 13px;
        font-weight: 700;
      }
    }
    .txt {
      line-height: 1.8;
    }
  }
  .btn_more {
    margin-top: 2%;
  }
}


/*  btn_other
 *---------------------------------------------------------- */

.btn_other {
  margin: 0 auto;
  width: 86%;
  a {
    background: #FFF;
    border: 1px solid #403223;
    color: #403223;
    &::after {
      border-color: #403223;
    }
  }
}

/*  INSTA
 *---------------------------------------------------------- */

.section_instagram {
  margin: 15% 5% 10%;
  text-align: center;
}

.section_instagram h2 {
  margin-bottom: 2%;
}

.section_instagram h2 img {
  height: 38px;
}

.section_instagram .inner {
  margin: 0 auto;
  text-align: center;
}

.section_instagram li {
  width: 48%;
  display: inline-block;
  background: #FFF;
  border: 1px solid #C9C6C1;
  padding: 8px;
  text-align: left;
  margin-right: 2%;
  margin-bottom: 10px;
}

.section_instagram li:nth-child(2n) {
  margin-right: 0;
}

.section_instagram li:nth-child(2n+1) {
  clear: left;
}

.section_instagram li a {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.section_instagram li a:hover {
  opacity: 1;
}

.section_instagram li .ig_thumb {
  width: 100%;
  height:100%;
}

.section_instagram li .ph {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
}

